import GoogleMapReact from "google-map-react";
import React, { useState } from "react";
import emailjs from "emailjs-com";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function ContactUs() {
  const defaultProps = {
    center: {
      lat: 30.8121387,
      lng: 72.80664,
    },
    zoom: 18,
  };
  const [formData, setFormData] = useState({
    from_name: "",
    contactNumber: "",
    email: "",
    message: "",
    to_name: "Liaqat",
  });

  const handleFromNameChange = (e) => {
    setFormData({ ...formData, from_name: e.target.value });
  };
  const handleContactNumberChange = (e) => {
    setFormData({ ...formData, contactNumber: e.target.value });
  };
  const handleemailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };
  const handleMessageChange = (e) => {
    setFormData({ ...formData, message: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_wtdq9vm", // Replace with your EmailJS service ID
        "template_rejqqne", // Replace with your EmailJS template ID
        formData,
        "5WoUmee7OjUG8BXyd" // Replace with your EmailJS user ID
      )
      .then((response) => {
        alert(
          "Thank you for contacting us! We appreciate your time and interest. Our team has received your message and will get back to you as soon as possible.!"
        );
        setFormData({contactNumber:"",email:"",message:"",from_name:""})

      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  return (
    <>
      <section className="contact_section layout_padding layout_padding-bottom">
        <div className="container-fluid">
          <div className="col-lg-4 col-md-5 offset-md-1">
            <div className="heading_container">
              <h2>Contact Us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-5 offset-md-1">
              <div className="form_container">
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="from_name"
                      value={formData.from_name}
                      onChange={handleFromNameChange}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      name="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleContactNumberChange}
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleemailChange}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="message-box"
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleMessageChange}
                    />
                  </div>
                  <div className="btn_box">
                    <button>SEND</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 px-0">
              <div className="map_container">
                <div className="map">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={30.812}
                      lng={72.806}
                      text="Textile Array"
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
