// import "./styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Slider() {
  const images = [
    {
      key: 101,
      id: "102",
      author: "Ben Moore",
      width: 1200,
      height: 400,
      url: "./Content/images/workers-textile.png",
      download_url: "./Content/images/workers-textile.png",
      text: "The textile sourcing and inspection service",
    },
    {
      key: 102,
      id: "103",
      author: "Ilham Rahmansyah",
      width: 1000,
      height: "400px",
      url: "./Content/images/yarn.png",
      download_url: "./Content/images/yarn.png",
      text: "Ensuring Superior Quality with Cost-Effective Textile Solutions",
    },
    {
      key: 103,
      id: "104",
      author: "Dyaa Eldin",
      width: 1200,
      height: 400,
      url: "./Content/images/TA1.jpg",
      download_url: "./Content/images/TA1.jpg",
      text: "We only source the highest quality Textile products",
    },
    {
      key: 104,
      id: "106",
      author: "Arvee Marie",
      width: 1200,
      height: 400,
      url: "./Content/images/Textile1.jpg",
      download_url: "./Content/images/Textile1.jpg",
      text: "Smart, Affordable Textile Quality Assurance",
    },
    {
      key: 105,
      id: "107",
      author: "Lukas Schweizer",
      width: 1200,
      height: 400,
      url: "./Content/images/TA4.jpg",
      download_url: "./Content/images/TA4.jpg",
      text: "The textile sourcing and inspection service",
    },
    {
      key: 106,
      id: "108",
      author: "Florian Klauer",
      width: 1200,
      height: 400,
      url: "./Content/images/TA6.jpg",
      download_url: "./Content/images/TA6.jpg",
      text: "Balancing Quality and Affordability in Textiles",
    },
    {
      key: 107,
      id: "109",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA7.jpg",
      download_url: "./Content/images/TA7.jpg",
      text: "Innovating for Precision in Textile Quality and Sourcing",
    },
    {
      key: 108,
      id: "110",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA8.jpg",
      download_url: "./Content/images/TA8.jpg",
      text: "Setting Global Standards in Textile Quality and Sourcing",
    },
    {
      key: 109,
      id: "111",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA9.webp",
      download_url: "./Content/images/TA9.webp",
      text: "Ensuring Excellence Through Every Threads",
    },
    {
      key: 110,
      id: "112",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA10.jpg",
      download_url: "./Content/images/TA10.jpg",
      text: "Your Trusted Partner in Textile Quality and Procurement",
    },
    {
      key: 111,
      id: "113",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA7.jpg",
      download_url: "./Content/images/TA7.jpg",
      text: "Empowering Ethical and Sustainable Textile Solutions",
    },
    {
      key: 112,
      id: "114",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA11.jpg",
      download_url: "./Content/images/TA11.jpg",
      text: "Revolutionizing Textile Quality with Cutting-Edge Technology",
    },
    {
      key: 113,
      id: "115",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA12.jpg",
      download_url: "./Content/images/TA12.jpg",
      text: "Bridging Quality and Cost Efficiency Through Technology",
    },
  ];
  return (
    <>
      <section className="slider_section ">
        <div
          id="customCarousel21"
          className="carousel slide"
          data-ride="carousel"
        >
          <Carousel
            className="crsl"
            autoPlay
            infiniteLoop
            centerMode
            interval={1000}
            autoplayHoverPause={false}
          >
            {images.map((image) => (
              <>
                <img
                  src={image.download_url}
                  id={image.id}
                  alt={image.author}
                />
                <span className="legend">
                  <h3>{image.text}</h3>
                </span>
              </>
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
}
